<div 
      class="w-full rounded-full overflow-hidden flex"
      [style.background-color]="getBackgroundColor(color)"
      [style.height.px]="height">
      <div
        class="rounded-full transition-all duration-300 ease-in-out"
            [style.width.%]="progress"
            [style.height.px]="height"
            [style.background-color]="getColor(color)">
      </div>
</div>